import React from 'react';
import FooterSocial from '../layout/partials/FooterSocial';

export default function confirm({...props}) {

    return(
        <div >
            <FooterSocial />
        </div>
    )
}